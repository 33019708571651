.Tab--container {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 12px 0;
    box-sizing: border-box;
    border-radius: 24px;

    transition: background .2s;
}

.Tab--container svg, .Tab--container img {
    width: 24px;
    height: 24px;
    margin: 0 auto;
}

.Tab--container p {
    margin-top: 6px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    color: var(--secondary_hint);
}

.Tab--container p.active {
    color: var(--link_color);
}