
.LeagueTitle.wood {
  background: linear-gradient(156.58deg, #C97E37 17.17%, #461305 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.iron {
  background: linear-gradient(156.58deg, #AE9684 17.17%, #604E3F 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.bronze {
  background: linear-gradient(156.58deg, #FEE4B0 17.17%, #743B27 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.silver {
  background: linear-gradient(156.58deg, #CECAC9 17.17%, #B3B1AE 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.LeagueTitle.gold {
  background: linear-gradient(156.58deg, #FFF5DE 17.17%, #CFA365 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.platinum {
  background: linear-gradient(156.58deg, #F7F7EF 17.17%, #225C47 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.diamond {
  background: linear-gradient(156.58deg, #FCF09A 17.17%, #FCF8E9 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueTitle.master {
  background: linear-gradient(156.58deg, #D38236 17.17%, #150D56 84.45%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}