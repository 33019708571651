.splide__arrow:disabled.splide__arrow--prev {
  opacity: 0;
  cursor: default;
}

.splide__arrow:disabled.splide__arrow--next {
  opacity: 1;
}

.splide__arrow {
  height: fit-content;
  width: fit-content;
  top: unset;
  transform: none;
}

.splide__arrow:focus-visible, .splide__arrow:focus-within {
  outline: 0;
}

.splide__pagination li {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.splide__pagination__page {
  background-color: var(--link_color);
  opacity: 0.1;
}

.splide__pagination__page.is-active {
  background-color: var(--link_color);
  opacity: 1;
}