.CellContainer--container {
    width: 100%;
    padding: 24px 16px;
    box-sizing: border-box;
    border-radius: 24px;
    background: var(--foreground_color);
}

.CellContainer--container .Cell--container {
    margin-bottom: 16px;
}
.CellContainer--container .Cell--container:last-child {
    margin-bottom: 0;
}