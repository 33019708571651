.LeagueValue.wood {
  background: radial-gradient(50% 50% at 50% 50%, #C97E37 0%, #461305 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.iron {
  background: radial-gradient(50% 50% at 50% 50%, #AE9684 0%, #604E3F 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.bronze {
  background: radial-gradient(50% 50% at 50% 50%, #FEE4B0 0%, #743B27 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.silver {
  background: radial-gradient(50% 50% at 50% 50%, #C5C2BE 0%, #53514E 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.gold {
  background: radial-gradient(50% 50% at 50% 50%, #FFE4BA 0%, #DEA44D 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.platinum {
  background: radial-gradient(50% 50% at 50% 50%, #F7F7EF 0%, #225C47 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.diamond {
  background: radial-gradient(50% 50% at 50% 50%, #FCF09A 0%, #FCF8E9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LeagueValue.master {
  background: radial-gradient(50% 50% at 50% 50%, #D38236 0%, #150D56 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}