/*.Panel--container {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    padding: 24px 16px 32px 16px;
    box-sizing: border-box;

    animation: scaleUp 0.3s ease;
}*/

.Panel--container {
    width: 100%;
    max-height: calc(100% - 76px);
    overflow-y: auto;

    box-sizing: border-box;

    animation: scaleUp 0.7s ease;
}

.Panel--container.full {
    max-height: 100%;
}

.Panel--content.between {
    height: 100%;
    justify-content: space-between;
}

.Panel--content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@keyframes scaleUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}