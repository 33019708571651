.ModalDonate--cellwrap {
  background: linear-gradient(180deg, rgba(8, 10, 35, 0) 0%, rgba(8, 10, 35, 0.0263704) 8.07%, rgba(8, 10, 35, 0.0519153) 15.08%, rgba(8, 10, 35, 0.0770549) 21.23%, rgba(8, 10, 35, 0.102209) 26.69%, rgba(8, 10, 35, 0.127798) 31.67%, rgba(8, 10, 35, 0.154242) 36.36%, rgba(8, 10, 35, 0.18196) 40.96%, rgba(8, 10, 35, 0.211373) 45.65%, rgba(8, 10, 35, 0.242901) 50.63%, rgba(8, 10, 35, 0.276964) 56.09%, rgba(8, 10, 35, 0.313981) 62.23%, rgba(8, 10, 35, 0.354374) 69.23%, rgba(8, 10, 35, 0.398561) 77.3%, rgba(8, 10, 35, 0.446963) 86.63%, rgba(8, 10, 35, 0.5) 97.4%);
  flex: 1 1 calc(50% - 6px);
}

.ModalDonate--ellipse {
  background: radial-gradient(ellipse at 50% 50%, #4f3cc862, #87755A00 80%) no-repeat;
  background-size: 100% 50%;
  z-index: 0;
}

.ModalDonate--amount {
  color: var(--link_color)
}

.ModalTexturedBg, .ModalPentagonBg {
  position: relative;
}

.ModalTexturedBg > *, .ModalPentagonBg > * {
  z-index: 3;
}

.ModalTexturedBg::before {
  content: "";
  background: url('../assets/images/extras/donateTexture.png') repeat;
  background-size: 72px 72px;
  position: absolute;
  opacity: 0.2;
  overflow: hidden;
  z-index: 2;
}

.ModalPentagonBg::before {
  content: "";
  background: url('../assets/images/extras/donatePentagon.png');
  position: absolute;
  overflow: hidden;
  z-index: 2;
}

.ModalBeforeInset::before {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.strike-through {
  background: linear-gradient(to left top, transparent 47.75%, #AAAAAA 49.5%, #AAAAAA 50.5%, transparent 52.25%);
}