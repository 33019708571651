.League--selector :not(.League--ellipse) {
  z-index: 1;
}

.League--self {
  background: rgb(8, 10, 33);
}

.LeagueUser--container.self {
  background: linear-gradient(rgba(22, 23, 47, 1), rgba(22, 23, 47, 1)) padding-box,
              linear-gradient(90deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%) border-box;
  border: 1px solid transparent;
} 

.LeagueUser--container.other {
  background: rgba(22, 23, 47, 1)
}

.LeagueUser--position, .League--bankboxsub {
  color: var(--secondary_hint)
}

.League--bankbox.wood {
  border-color: #C97E374D;
  background: linear-gradient(to right bottom, #604E3F88 0%, rgba(0, 32, 1, 0) 50%, #604E3F88 100%)
}

.League--bankbox.iron {
  border-color: #AE96844D;
  background: linear-gradient(to right bottom, #604E3F88 0%, rgba(0, 32, 1, 0) 50%, #604E3F88 100%)
}

.League--bankbox.bronze {
  border-color: #FEE4B04D;
  background: linear-gradient(to right bottom, #743B2788 0%, rgba(0, 32, 1, 0) 50%, #743B2788 100%)
}

.League--bankbox.silver {
  border-color: #CECAC94D;
  background: linear-gradient(to right bottom, #333231 0%, rgba(0, 32, 1, 0) 50%, #333231 100%)
}

.League--bankbox.gold {
  border-color: #FFF5DE4D;
  background: linear-gradient(to right bottom, #87755A88 0%, rgba(0, 32, 1, 0) 50%, #87755A88 100%)
}

.League--bankbox.platinum {
  border-color: #F7F7EF4D;
  background: linear-gradient(to right bottom, #225C4788 0%, rgba(0, 32, 1, 0) 50%, #225C4788 100%)
}

.League--bankbox.diamond {
  border-color: #FCF8E94D;
  background: linear-gradient(to right bottom, #fcf8e944 0%, rgba(0, 32, 1, 0) 50%, #FCF8E944 100%)
}

.League--bankbox.master {
  border-color: #D382364D;
  background: linear-gradient(to right bottom, #150D5688 0%, rgba(0, 32, 1, 0) 50%, #150D5688 100%)
}

.League--ellipse.wood {
  background: radial-gradient(ellipse at 50% 50%, #604E3F, #87755A00 80%);
}

.League--ellipse.iron {
  background: radial-gradient(ellipse at 50% 50%, #604E3F, #87755A00 80%);
}

.League--ellipse.bronze {
  background: radial-gradient(ellipse at 50% 50%, #743B2788, #87755A00 80%);
}

.League--ellipse.silver {
  background: radial-gradient(ellipse at 50% 50%, #333231, #87755A00 80%);
}

.League--ellipse.gold {
  background: radial-gradient(ellipse at 50% 50%, #87755A, #87755A00 80%);
}

.League--ellipse.platinum {
  background: radial-gradient(ellipse at 50% 50%, #225C47, #87755A00 80%);
}

.League--ellipse.diamond {
  background: radial-gradient(ellipse at 50% 50%, #FCF8E988, #87755A00 80%);
}

.League--ellipse.master {
  background: radial-gradient(ellipse at 50% 50%, #150D56, #87755A00 80%);
}