.BoostItem--divider {
  color: var(--secondary_hint);
}

.BoostItem--level {
  color: var(--secondary_hint);
}

.Boosts-sectiontitle {
  color: var(--secondary_hint);
}