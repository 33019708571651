
.InviteInfo--gradient {
  background: linear-gradient(90deg, #736AFF 0%, #3697FE 50.4%, #E457E0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.InviteInfo--wrapper {
  background: linear-gradient(101.24deg, rgba(115, 106, 255, 0.22) -0.52%, rgba(54, 151, 254, 0.22) 50.14%, rgba(255, 124, 242, 0.22) 100%);
}
.InviteInfo--container {
  background: url('../../../../assets/images/extras/gift.png') no-repeat;
  background-position: calc(100% + 52px);
  background-size: 43%;
}