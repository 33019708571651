.Cell--container {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Cell--before {
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.Cell--before img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.Cell--after {
    margin-left: auto;
    color: var(--gray_light_color);
}

.Cell--title-container {
    display: flex;
}

.Cell--title {
    font-size: 18px;
    font-weight: 500;
    color: var(--white_color);
}

.Cell--title-after {
    margin-left: 16px;
}

.Cell--children {
    margin-top: 3px;

    font-weight: 400;
    font-size: 16px;
    color: var(--gray_light_color);

    display: flex;
    gap: 8px;
}