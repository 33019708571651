.Tasks--sectiontitle p {
  color: var(--secondary_hint);
}

.TaskItem--price > :not(:first-child)::before {
  content: "";
  border-left: var(--secondary_hint) 2px solid;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0px;
  height: 12px;
}

div[id*="tads-container-"] {
  gap: 0.75rem !important;
}

.tads {
  padding: 0.5rem 0.75rem !important;
  background-color: #16172F !important;
  align-items: center !important;
  gap: 0.75rem !important;
  border-radius: 1rem !important;
}

.tads-image {
  border-radius: 0.5rem !important;
}