.CoinButton--container {
    width: 100%;
    padding: 16px 22px;
}

.CoinButton--content {
    margin: 0 auto;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    background: url("../../../../assets/images/coins/coin.svg") no-repeat center;
    background-size: contain;

    cursor: pointer;
    transition: transform .2s;
}

.CoinButton--content:active {
    transform: scale(.90);
}

.CoinButton--digits--container {
    position: fixed;
    top: 0;
    left: 0;
}

.CoinButton--digits--container p {
    position: absolute;
    z-index: 999;
    font-weight: 700;
    font-size: 54px;
    pointer-events: none;

    animation: click-animation 1s ease forwards;
}

@keyframes click-animation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-200px);
    }
}

.square{
    height: 94%;
    width: 94%;
    background: rgba(238, 255, 3, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -47% 0 0 -47%;
  }
  
  .black{ 
    background: transparent;  
    animation: rotate 10s infinite linear; 
  }
  
  @keyframes rotate{
    0%{ transform: rotate(0deg); }
      100%{ transform: rotate(360deg); }	
  }

/*
@media screen and (max-height: 780px) {
    .CoinButton--container {
        margin-bottom: 24px;
    }
    .CoinButton--content {
        width: min(calc(100% - 48px), 240px);
    }
}*/
