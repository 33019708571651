.BuildingsTab--name {
  color: var(--secondary_hint);
}

.Buildings--tabs {
  max-height: calc(100% - 140px);
}

.BuildingsTab--purchased {
  color: var(--secondary_hint);
}

.BuildingItemBox--title {
  color: var(--white_color);
}

.BuildingItemBox--hour p {
  color: var(--secondary_hint);
}

.BuildingItemBox--full p {
  color: var(--secondary_hint);
}

.BuildingItemBox--profitValue p {
  color: var(--white_color);
}

.BuildingItemBox--buyHour p {
  color: var(--white_color);
}

.BuildingItemBox--count {
  background: var(--link_color);
  color: var(--white_color);
}

.ImprovementItemBox--levelpremium {
  background: linear-gradient(90deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%);
  
}

.ImprovementItemBox--levelnormal {
  background: linear-gradient(180deg, rgba(60, 61, 81) 0%, rgba(35, 37, 57) 100%);
}