.ScreenLoader--container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--background_color);
    z-index: 60;
}

.ScreenLoader--content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(79, 60, 200, 0.12) 100%);
}

.ScreenLoader--content h1 {
    margin-top: 24px;
    color: #3F3F46;
}

.ScreenLoader--ellipse {
    background: radial-gradient(ellipse at 50% 50%, #4f3cc867, #87755A00 80%);
    z-index: 30;
}