.Premium--wrapper {
  background: linear-gradient(135deg, #17AEE970 0%, #E44EBF70 50.4%, #FE701470 100%);
}
.Premium--container {
  background: url('../../assets/images/preload/crown.png') no-repeat;
  background-position: calc(100% - 10px) 5px;
  background-size: 43%;
}

.Premium--page > :not(.Premium--ellipse) {
  z-index: 10;
}

.Premium--ellipse {
  background: linear-gradient(135deg, #17AEE962 20%, #E44EBF60 50%, #FE701462 80%) no-repeat;
  background-size: 100% 80%;
  mask-image: radial-gradient(closest-corner ellipse at 50% 40%, white 0%, transparent 80%);
  z-index: 0;
}

.Premium--cellwrap {
  background: linear-gradient(130.24deg, rgba(61, 39, 76, 0) -0.52%, rgba(61, 39, 76, 1) 100%);
}

.PentagonBg {
  position: relative;
}

.PentagonBg > * {
  z-index: 3;
}

.PentagonBg::before {
  content: "";
  background: url('../../assets/images/extras/donatePentagon.png');
  position: absolute;
  overflow: hidden;
  z-index: 2;
}
